import { Box, Text } from '@chakra-ui/react'
import { DOPPEL_TEXT_GREY, DOPPEL_DARK_SECONDARY } from '@/utils/style'

type AddObjectButtonProps = {
  label: string
  onClick: () => void
}

const AddObjectButton = ({ label, onClick }: AddObjectButtonProps) => {
  return (
    <>
      <Box
        _hover={{ backgroundColor: DOPPEL_DARK_SECONDARY, cursor: 'pointer' }}
        borderRadius={4}
        mt={2}
        onClick={onClick}
        px={4}
        py={2}
        transition="background-color 0.2s"
        w="100%"
      >
        <Text fontWeight={400} textColor={DOPPEL_TEXT_GREY}>
          {label}
        </Text>
      </Box>
    </>
  )
}

export default AddObjectButton

import React from 'react'
import { Select, Spinner, Text } from '@chakra-ui/react'
import { useGetTrademarksQuery } from '@/generated/graphql'
import { VALID_TRADEMARK_STATUSES } from '@/utils/constants'
import { DOPPEL_BREACH_RED } from '@/utils/style'

interface TrademarkDropdownProps {
  alertId?: string // or number, if needed
  spoofingEntityId: string // If you need multiple IDs, pass an array; see comment below.
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  value?: string // if you're controlling the <Select> value from outside
}

/**
 * Renders a dropdown of trademarks based on the provided spoofingEntityId.
 * If you have multiple IDs, pass an array to the query (e.g. `_in: spoofingEntityIds`).
 */
const TrademarkDropdown: React.FC<TrademarkDropdownProps> = ({
  alertId,
  spoofingEntityId,
  onChange,
  value,
}) => {
  const { data, loading, error } = useGetTrademarksQuery({
    variables: {
      where: {
        spoofing_entity_id: { _eq: spoofingEntityId },
        status: { _in: VALID_TRADEMARK_STATUSES },
      },
    },
    skip: !spoofingEntityId,
  })

  if (loading) return <Spinner size="sm" />
  if (error) {
    return (
      <Text color={DOPPEL_BREACH_RED} fontSize="sm">
        Error loading trademarks
      </Text>
    )
  }

  const trademarks = data?.trademarks ?? []

  return (
    <Select
      placeholder="Select Trademark Option"
      width="200px"
      fontSize="xs"
      mt={2}
      onChange={onChange}
      value={value}
    >
      {trademarks.map((tm) => (
        <option key={tm.id} value={tm.id}>
          {tm.name}
        </option>
      ))}
    </Select>
  )
}

export default TrademarkDropdown

import { HStack, useDisclosure } from '@chakra-ui/react'
import { useIsEmployeeView } from '../../hooks/id_token_claims'
import { useSelectedReportStatus, useSelectedReportType } from '../../pages/reports'
import {
  ReportStatus,
  ReportType,
  STATUS_ACTION_LABELS,
  getStatusLabel,
} from '../../utils/constants'
import { getAllowedActionButtons } from '../report_detail/report_detail_button'
import BulkClassifyButton from './bulk_classify_button'
import BulkOpenButton from './bulk_open_button'
import BulkCopyButton from './bulk_copy_button'
import StatusChangeButton from './status_change_button'
import BulkScreenshotButton from './bulk_screenshot_button'
import BulkTagButton from './bulk_tag_button'
import { DOPPEL_BREACH_RED } from '@/utils/style'
import { getDoppelReportUrl, getExternalReportId } from '@/utils/reports/report_utils'
import { useMemo, useState } from 'react'
import DoppelModal from '../shared/doppel_modal'
import BulkReroutePlatformSubtypeButton from './bulk_reroute_platform_subtype_button'
import BulkNotesButton from './bulk_notes_button'
import BulkAssignAlertsContainer from '../internal/bulk_assign_alerts_container'
import BulkTagAlertsModal from '../enforcements/bulk_tag_alerts_modal'
import CreateAlertLinkButton from '../alert_links/create_alert_link_button'

// State Machine (keep this up-to date!)
//
// Needs Review -> Reported, Ignored, No-Action, Internal Review, Internal Ignore
// Reported -> Needs Review, Delisted
// Delisted -> Needs Review, Reported
// Ignored -> Needs Review
// No Action -> Needs Review
// Internal Review -> Needs Review, Internal Ignore
// Internal Ignore -> Needs Review, Internal Review

// Currently only used in Spoof Table, need to refactor to make it more generic
const BulkActionButtons = ({
  selectedRows,
  actionLoadingState,
  updateReportFunc,
  isOrgUnifiedView,
}) => {
  const MAX_SELECTED_BULK_TAG_ROWS = 10
  const [selectedReportStatus] = useSelectedReportStatus()
  const [selectedReportType] = useSelectedReportType()
  const [isEmployeeView] = useIsEmployeeView()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [pendingAction, setPendingAction] = useState(null)
  const isBulkTagModalDisabled = useMemo(
    () => selectedRows.length > MAX_SELECTED_BULK_TAG_ROWS,
    [selectedRows],
  )
  const {
    isOpen: isTagModalOpen,
    onOpen: onTagModalOpen,
    onClose: onTagModalClose,
  } = useDisclosure()
  const DISABLED_BULK_TAG_MODAL_MESSAGE =
    'Bulk Action disabled. Please select 10 alerts or fewer.'

  const handleActionClick = (action, callback) => {
    setPendingAction({ action, callback, isDelete: action === 'Internal Archived' })
    onOpen()
  }

  const handleConfirm = () => {
    if (pendingAction) {
      pendingAction.callback()
    }
    onClose()
  }

  const handleBulkTaggingAction = () => {
    if (isBulkTagModalDisabled) return
    onTagModalOpen()
  }

  // Logic for when to open bulk tag alerts modal vs old bulk action modal
  // To be expanded upon as more report types are added
  const shouldOpenBulkActionTaggingModal = (reportStatus) => {
    if (
      reportStatus === ReportStatus.REPORTED.toLowerCase() &&
      selectedReportType === ReportType.DOMAINS &&
      isEmployeeView
    ) {
      return true
    }
    return false
  }

  if (selectedRows.length === 0) {
    return <></>
  }

  return (
    <>
      <HStack spacing={2}>
        {getAllowedActionButtons(selectedReportStatus, isEmployeeView).map(
          (reportStatus: ReportStatus) => (
            <StatusChangeButton
              actionLoadingState={actionLoadingState}
              handleClick={() => {
                shouldOpenBulkActionTaggingModal(reportStatus)
                  ? handleBulkTaggingAction()
                  : handleActionClick(
                      getStatusLabel(reportStatus, selectedReportType),
                      () => updateReportFunc(selectedRows, { status: reportStatus }),
                    )
              }}
              hoverText={
                shouldOpenBulkActionTaggingModal(reportStatus) && isBulkTagModalDisabled
                  ? DISABLED_BULK_TAG_MODAL_MESSAGE
                  : ''
              }
              key={reportStatus}
              selectedRows={selectedRows}
              statusTo={reportStatus}
              text={getStatusLabel(
                reportStatus,
                selectedReportType,
                STATUS_ACTION_LABELS,
              )}
            />
          ),
        )}

        <BulkTagButton
          isOrgUnifiedView={isOrgUnifiedView}
          selectedRows={selectedRows}
        />

        <BulkNotesButton
          selectedRows={selectedRows}
          updateReportFunc={updateReportFunc}
        />
      </HStack>

      <HStack mt={2} spacing={2}>
        <BulkScreenshotButton selectedRows={selectedRows} />

        {isEmployeeView && (
          <BulkOpenButton
            selectedRows={selectedRows}
            selectorFunc={(row) =>
              getDoppelReportUrl(row.original, row.original.organization)
            }
          />
        )}

        {isEmployeeView && <BulkCopyButton selectedRows={selectedRows} />}

        {isEmployeeView && (
          <BulkClassifyButton
            loading={actionLoadingState?.classification !== undefined}
            selectedRows={selectedRows}
            updateReportFunc={updateReportFunc}
          />
        )}

        {isEmployeeView && (
          <BulkReroutePlatformSubtypeButton
            loading={actionLoadingState?.platformSubtype !== undefined}
            selectedRows={selectedRows}
            updateAlertFunc={updateReportFunc}
          />
        )}

        {isEmployeeView && (
          <CreateAlertLinkButton
            alertIds={selectedRows.map((row) =>
              getExternalReportId(row.organization?.abbr_name, row.external_id),
            )}
            alertUuids={selectedRows.map((row) => row.id)}
            isBulkActionsView={true}
          />
        )}

        {isEmployeeView && (
          <BulkAssignAlertsContainer selectedRows={selectedRows} showIcon={false} />
        )}

        {isEmployeeView && (
          <StatusChangeButton
            actionLoadingState={actionLoadingState}
            bgColor={DOPPEL_BREACH_RED}
            handleClick={() =>
              handleActionClick(
                getStatusLabel(ReportStatus.ENUM_INTERNAL_ARCHIVED, selectedReportType),
                () =>
                  updateReportFunc(selectedRows, {
                    status: ReportStatus.ENUM_INTERNAL_ARCHIVED,
                  }),
              )
            }
            key={ReportStatus.ENUM_INTERNAL_ARCHIVED}
            selectedRows={selectedRows}
            statusTo={ReportStatus.ENUM_INTERNAL_ARCHIVED}
            text={'Delete'}
          />
        )}
      </HStack>

      <DoppelModal
        isDanger={pendingAction?.isDelete}
        isOpen={isOpen}
        message={
          pendingAction?.isDelete
            ? `Are you sure you want to delete ${selectedRows.length} reports?`
            : `Are you sure you want to move ${selectedRows.length} reports to ${pendingAction?.action}?`
        }
        onClose={onClose}
        primaryAction={handleConfirm}
        primaryLabel="Confirm"
        title="Confirm Action"
      />

      <BulkTagAlertsModal
        isOpen={isTagModalOpen}
        onClose={onTagModalClose}
        selectedRows={selectedRows}
      />
    </>
  )
}

export default BulkActionButtons

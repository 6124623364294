import { HStack, Icon, Tooltip } from '@chakra-ui/react'
import { ReactElement } from 'react'
import TableTimestamp from '@/components/tables/table_timestamp'
import moment from 'moment'
import { TimeIcon } from '@chakra-ui/icons'
import { DOPPEL_BREACH_RED } from '@/utils/style'
import { AGED_ALERT_DAYS_THRESHOLD } from '@/utils/constants'

type SpoofReportsTableLastReportedDateCellProps = {
  lastReportedAt: string
  isEmployeeView: boolean
}

function SpoofReportsTableLastReportedDateCell({
  lastReportedAt,
  isEmployeeView,
}: SpoofReportsTableLastReportedDateCellProps): ReactElement {
  const inputDate = moment(lastReportedAt)
  const agedReportDate = moment()
    .startOf('day')
    .subtract(AGED_ALERT_DAYS_THRESHOLD, 'days')
    .format('YYYY-MM-DDTHH:mm:ss')
  const isAgedReport = !inputDate.isAfter(agedReportDate)

  return (
    <HStack>
      <TableTimestamp date={lastReportedAt} />

      {isEmployeeView && isAgedReport && (
        <Tooltip label={`Aged report over ${AGED_ALERT_DAYS_THRESHOLD} days old`}>
          <Icon as={TimeIcon} color={DOPPEL_BREACH_RED} fontSize={12} />
        </Tooltip>
      )}
    </HStack>
  )
}

export default SpoofReportsTableLastReportedDateCell
